import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';
import SideImageInfoSection from '../components/SideImageInfoSection';
import DetailList from '../components/DetailList';
import { PageWrap } from '../styles/common';

export const ServicesDetailTemplate = ({
	name,
	displayName,
	infoSection,
	mastheadImage,
	detailList,
}) => {
	return (
		<PageWrap>
			<Masthead image={mastheadImage} title={name} />
			<SideImageInfoSection
				image={infoSection.side_image}
				imageAlt={`Service image`}
				isLeft={false}
				mainContent={infoSection.info_content}
			/>
			{detailList && <DetailList detail_list={detailList} />}
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
			<SideImageInfoSection
				image={`/img/SpergelCorporate_Industries.png`}
				imageAlt={`Serving many industries`}
				isLeft={true}
				mainContent={`## With Over 30 Years of Small Business & Corporate Debt Help, Trust the Spergel Strategic Approach\n\nSpergel Licensed Insolvency Trustees have over 30 years of experience developing business solutions for a wide range of industries. You can count on our small business and corporate debt help solutions that we’ve used to assist companies across various industries, including manufacturing, services, start-ups, and technology. We make it our business to understand yours and provide customized and cost-effective solutions.`}
			/>
		</PageWrap>
	);
};

const ServicesDetail = ({ data }) => {
	const {
		name,
		display_name,
		info_section,
		masthead_image,
		detail_list,
	} = data.servicesJson;
	return (
		<Layout>
			<Helmet>
				<title>{name} | Spergel Corporate</title>
			</Helmet>
			<ServicesDetailTemplate
				name={name}
				displayName={display_name}
				infoSection={info_section}
				mastheadImage={masthead_image}
				detailList={detail_list}
			/>
		</Layout>
	);
};

export default ServicesDetail;

export const serviceDetailQuery = graphql`
	query Service($id: String!) {
		servicesJson(id: { eq: $id }) {
			fields {
				slug
			}
			name
			display_name
			info_section {
				side_image
				info_content
			}
			masthead_image
			detail_list {
				heading
				content
			}
		}
	}
`;
