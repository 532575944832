import React, { useState } from 'react';
import styled from 'styled-components';

export default ({ detail, isShowing }) => {
	const itemShowing = isShowing || false;
	const [showing, setShowing] = useState(itemShowing);
	return (
		<Wrap onClick={() => setShowing(!showing)} showing={showing}>
			<h4>{detail.heading}</h4>
			<div style={{ display: `${showing ? 'block' : 'none'}` }}>
				{detail.content}
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	cursor: pointer;
	padding: 0 4rem;
	h4 {
		font-size: 1.5rem;
		margin-bottom: 1rem;
	}
	background-image: ${(p) =>
		p.showing ? 'url(/img/minus.svg)' : 'url(/img/plus.svg)'};
	background-repeat: no-repeat;
	background-size: 1rem;
	background-position: 16px 11px;
`;
