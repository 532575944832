import React from 'react';
import styled from 'styled-components';
//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import AccordionItem from './AccordionItem';

export default ({ detail_list, itemsShowing }) => {
	const splitDetails = splitArrayInHalf(detail_list);
	return (
		<Wrap detail_list={detail_list}>
			<div className="container">
				<h3 style={{ textAlign: 'center' }}>How can we help your business?</h3>
				<div className="columns">
					<div className="column">
						{splitDetails[0].map((detail, i) => (
							<AccordionItem detail={detail} isShowing={itemsShowing} key={i} />
						))}
					</div>
					<div className="column">
						{splitDetails[1].map((detail, i) => (
							<AccordionItem detail={detail} isShowing={itemsShowing} key={i} />
						))}
					</div>
				</div>
			</div>
		</Wrap>
	);
};

const Wrap = styled.div`
	padding: 4rem 1rem;
	background-image: url('/img/SpergelCorporate_Home_Search.jpg');
	background-size: cover;
	background-position: center center;
	h3 {
		font-size: 1.5rem;
		margin-bottom: 2rem;
	}
	display: ${(p) => (p.detail_list.length === 0 ? 'none' : 'block')};
`;

// Split array in half.
// Larger half will always be first.
// Returns array of halves.
function splitArrayInHalf(arr) {
	const half = Math.ceil(arr.length / 2);
	const firstHalf = arr.slice(0, half);
	const secondHalf = arr.slice(half);
	return [firstHalf, secondHalf];
}
